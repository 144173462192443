<template>
  <div class="tower">
    <VueProductSpinner
      :images="imgs"
      :slider="true"
      :infinite="true"
      :speed="3"
      :touchDrag="true"
      :mouseDrag="true"
      sliderClass="custom-slider"
    />
  </div>
</template>

<script>
import VueProductSpinner from "vue-product-spinner";
import IMG1 from "../assets/tower/Untitled Project_0001.png";
import IMG2 from "../assets/tower/Untitled Project_0002.png";
import IMG3 from "../assets/tower/Untitled Project_0003.png";
import IMG4 from "../assets/tower/Untitled Project_0004.png";
import IMG5 from "../assets/tower/Untitled Project_0005.png";
import IMG6 from "../assets/tower/Untitled Project_0006.png";
import IMG7 from "../assets/tower/Untitled Project_0007.png";
import IMG8 from "../assets/tower/Untitled Project_0008.png";
import IMG9 from "../assets/tower/Untitled Project_0009.png";
import IMG10 from "../assets/tower/Untitled Project_0010.png";
import IMG11 from "../assets/tower/Untitled Project_0011.png";
import IMG12 from "../assets/tower/Untitled Project_0012.png";
import IMG13 from "../assets/tower/Untitled Project_0013.png";
import IMG14 from "../assets/tower/Untitled Project_0014.png";
import IMG15 from "../assets/tower/Untitled Project_0015.png";
import IMG16 from "../assets/tower/Untitled Project_0016.png";
import IMG17 from "../assets/tower/Untitled Project_0017.png";
import IMG18 from "../assets/tower/Untitled Project_0018.png";
import IMG19 from "../assets/tower/Untitled Project_0019.png";
import IMG20 from "../assets/tower/Untitled Project_0020.png";

export default {
  components: { VueProductSpinner },
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      imgs: [
        IMG1,
        IMG2,
        IMG3,
        IMG4,
        IMG5,
        IMG6,
        IMG7,
        IMG8,
        IMG9,
        IMG10,
        IMG11,
        IMG12,
        IMG13,
        IMG14,
        IMG15,
        IMG16,
        IMG17,
        IMG18,
        IMG19,
        IMG20,
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.vue-product-spinner img {
  height: 1000px !important;
  width: 100% !important;
}

.custom-slider {
  width: 300px;
  margin-top: 3em;
}

.tower{
  padding: 2em;
}
</style>
